import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../store/slices/auth';
import { Avatar, Box, Typography, Grid, Divider } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { getReviewsPerClient } from '../services';
import textRatingToNumber from '../services/textRatingToNumber';
import './ReviewsSection.css';
import moment from 'moment';

const Review = ({
  review: {
    rev_comment,
    msgReply = '',
    reviewer,
    starRating,
    replied = false,
    createTime,
    updateTime
  }
}) => {
  const stars = [];
  const rating = textRatingToNumber(starRating);
  for (let i = 0; i < rating; i++) {
    stars.push(<StarIcon className="star" />);
  }

  return (
    <Grid>
      <Box className="fullSize">
        <Box
          display="flex"
          flexDirection="row"
          className="review fullSize"
          justifyContent="spaceAround">
          <Box
            display="flex"
            flexDirection="column"
            className="opinion fullSize">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              className="opinionHeader">
              <Box className="profilePhoto">
                <Avatar
                  alt="Person"
                  src={reviewer?.profilePhotoUrl}
                  className="imgR"
                />
              </Box>
              <Box className="name">
                <Typography variant="h5">{reviewer?.displayName}</Typography>
              </Box>
              <Box display="flex" className="starsBox" alignItems="center">
                {stars.map((star, index) => (
                  <Box display="flex" flexDirection="row" key={index}>
                    <Box item>{star}</Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box>
              <Typography variant="body2">{rev_comment}</Typography>
            </Box>
          </Box>
          {replied && (
            <>
              <Box>
                <Divider orientation="vertical" />
              </Box>
              <Box className="response fullSize">
                <Typography variant="h6">Response:</Typography>
                <Typography variant="body2">{msgReply}</Typography>
              </Box>
            </>
          )}
        </Box>
        <Box className="horizontalDivider">
          <div
            style={{ color: 'gray', marginBottom: '15px', marginTop: '-25px' }}>
            {moment(createTime).format('dddd, MMMM Do YYYY, h:mm:ss a')}
          </div>
          <Divider orientation="horizontal" />
        </Box>
      </Box>
    </Grid>
  );
};

const ReviewContent = ({ id }) => {
  const [reviews, setReview] = useState({ loading: true, data: null });
  const { userInfo } = useSelector(authSelector);

  const getReviews = useCallback(async () => {
    const data = await getReviewsPerClient(id, userInfo.accessToken);
    setReview({ ...data.data, loading: data.loading });
  }, [id, userInfo.accessToken]);

  useEffect(() => {
    getReviews();
    return () => {
      setReview({});
    };
  }, [getReviews]);

  return (
    <div className="container">
      {reviews.loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Box display="flex" flexDirection="column" alignItems="center">
            {reviews.reviews?.map((review, index) => (
              <Review key={index} review={review} />
            ))}
          </Box>
        </>
      )}
    </div>
  );
};

export default ReviewContent;
