import React, { useState } from 'react';
import Select from 'react-select';
import './BusinessHours.css'; // Importa el archivo CSS

export const BUSINESSHOURS = [
  { value: 1, label: '1 AM' },
  { value: 2, label: '2 AM' },
  { value: 3, label: '3 AM' },
  { value: 4, label: '4 AM' },
  { value: 5, label: '5 AM' },
  { value: 6, label: '6 AM' },
  { value: 7, label: '7 AM' },
  { value: 8, label: '8 AM' },
  { value: 9, label: '9 AM' },
  { value: 10, label: '10 AM' },
  { value: 11, label: '11 AM' },
  { value: 12, label: '12 PM' },
  { value: 13, label: '1 PM' },
  { value: 14, label: '2 PM' },
  { value: 15, label: '3 PM' },
  { value: 16, label: '4 PM' },
  { value: 17, label: '5 PM' },
  { value: 18, label: '6 PM' },
  { value: 19, label: '7 PM' },
  { value: 20, label: '8 PM' },
  { value: 21, label: '9 PM' },
  { value: 22, label: '10 PM' },
  { value: 23, label: '11 PM' },
  { value: 0, label: '12 AM' }
];

const customMultiSelectorStyle = {
  control: (provided) => ({
    ...provided,
    width: '89px',
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#3B5998',
    color: 'black',
    borderRadius: '0px'
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '205px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '13px',
    maxHeight: '35px',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: 'white white',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
      borderRadius: '8px'
    }
  })
};

const Schedule = ({
  dayName,
  values,
  setFieldValue,
  errors,
  touched,
  onBlur
}) => {
  const [isOpen, setIsOpen] = useState(
    !(values?.businessHours[dayName.toLowerCase()] === '/')
  );

  const toggleState = () => {
    if (isOpen) {
      setFieldValue(`businessHours.${dayName.toLowerCase()}`, '/');
    }
    if (!isOpen) {
      setFieldValue(`businessHours.${dayName.toLowerCase()}`, '9/18');
    }
    setIsOpen(!isOpen);
  };

  const [startDate, setStartDate] = useState(
    values?.businessHours[dayName.toLowerCase()].split('/')[0] || '9'
  );
  const [endDate, setEndDate] = useState(
    values?.businessHours[dayName.toLowerCase()].split('/')[1] || '9'
  );

  return (
    <div className="schedule-container">
      <div className="schedule-row">
        <div className="schedule-day font">{dayName}</div>
        <div className="schedule-status">
          <span
            className={`font clickable ${isOpen ? 'open' : 'closed'}`}
            onClick={toggleState}>
            {isOpen ? 'Open' : 'Closed'}
          </span>
        </div>
        {isOpen && (
          <div className="schedule-hours">
            <Select
              required
              options={BUSINESSHOURS}
              className="basic-single"
              isSearchable={false}
              value={BUSINESSHOURS.find((bh) => bh.value === Number(startDate))}
              onChange={(selectedOption) => {
                setStartDate(selectedOption.value);
                setFieldValue(
                  `businessHours.${dayName.toLowerCase()}`,
                  `${selectedOption.value}/${
                    values?.businessHours[dayName.toLowerCase()].split('/')[1]
                  }`
                );
              }}
              styles={{
                ...customMultiSelectorStyle,
                control: (provided) => ({
                  ...provided,
                  ...customMultiSelectorStyle.control(),
                  ...(touched.serviceArea && errors.serviceArea
                    ? {
                        borderColor: 'red'
                      }
                    : {})
                })
              }}
            />
            <Select
              required
              options={BUSINESSHOURS}
              className="basic-single"
              isSearchable={false}
              value={BUSINESSHOURS.find((bh) => bh.value === Number(endDate))}
              onChange={(selectedOption) => {
                setEndDate(selectedOption.value);
                setFieldValue(
                  `businessHours.${dayName.toLowerCase()}`,
                  `${
                    values?.businessHours[dayName.toLowerCase()].split('/')[0]
                  }/${selectedOption.value}`
                );
              }}
              styles={{
                ...customMultiSelectorStyle,
                control: (provided) => ({
                  ...provided,
                  ...customMultiSelectorStyle.control(),
                  ...(touched.serviceArea && errors.serviceArea
                    ? {
                        borderColor: 'red'
                      }
                    : {})
                })
              }}
            />
          </div>
        )}
      </div>
      <div>
        {touched.businessHours?.[dayName.toLowerCase()] &&
          errors.businessHours?.[dayName.toLowerCase()] && (
            <div className="invalid-feedback d-inline">
              {errors.businessHours?.[dayName.toLowerCase()]}
            </div>
          )}
      </div>
    </div>
  );
};

const BusinessHours = ({ values, setFieldValue, errors, touched, onBlur }) => {
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  return (
    <div className="business-hours">
      <div className="font mb-2">Business Hours</div>
      {days.map((day) => (
        <Schedule
          key={day}
          dayName={day}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          onBlur={onBlur}
        />
      ))}
    </div>
  );
};

export default BusinessHours;
