import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/slices/auth';
import NoteField from '../NoteField';
import { getNotesInfo } from '../services/index';

const NoteContent = ({ id, open }) => {
  const [note, setNote] = useState({ loading: true, data: null });
  const { userInfo } = useSelector(authSelector);

  const getNote = useCallback(async () => {
    if (open) {
      const note = await getNotesInfo(id, userInfo.accessToken);
      setNote(note);
    }
  }, [id, open, userInfo.accessToken]);

  useEffect(() => {
    getNote();
    return () => {
      setNote({});
    };
  }, [getNote]);

  return (
    <div>
      {note.loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row mb-3">
            <div className="col">
              <NoteField title={'Content Guidelines Notes:'}>
                {note.data?.Content_Guidelines_Notes ? (
                  note.data?.Content_Guidelines_Notes
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Content Creator: '}>
                {note.data?.Content_Creator ? (
                  note.data?.Content_Creator
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Dashboard Link:'}>
                {note.data?.Dashboard_Link ? (
                  note.data?.Dashboard_Link
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Dropbox Folder:'}>
                {note.data?.Dropbox_Folder ? (
                  <a
                    className="text-break"
                    href={note.data?.Dropbox_Folder}
                    target="__blank"
                    rel="noreferrer noopener">
                    {note.data?.Dropbox_Folder}
                  </a>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Google Drive Client Folder Link:'}>
                {note.data?.Google_Drive_Client_Folder_Link ? (
                  <a
                    className="text-break"
                    href={note.data?.Google_Drive_Client_Folder_Link}
                    target="__blank"
                    rel="noreferrer noopener">
                    {note.data?.Google_Drive_Client_Folder_Link}
                  </a>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Facebook Link:'}>
                {note.data?.Facebook_URL ? (
                  <a
                    className="text-break"
                    href={note.data?.Facebook_URL}
                    target="__blank"
                    rel="noreferrer noopener">
                    {note.data?.Facebook_URL}
                  </a>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'GMB Google 2 URL:'}>
                {note.data?.GMB_Google_2_URL ? (
                  <a
                    className="text-break"
                    href={note.data?.GMB_Google_2_URL}
                    target="__blank"
                    rel="noreferrer noopener">
                    {note.data?.GMB_Google_2_URL}
                  </a>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Google Business Listing URL:'}>
                {note.data?.Google_Business_Listing_URL ? (
                  <a
                    className="text-break"
                    href={note.data?.Google_Business_Listing_URL}
                    target="__blank"
                    rel="noreferrer noopener">
                    {note.data?.Google_Business_Listing_URL}
                  </a>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Yelp Link:'}>
                {note.data?.Yelp_URL ? (
                  <a
                    className="text-break"
                    href={note.data?.Yelp_URL}
                    target="__blank"
                    rel="noreferrer noopener">
                    {note.data?.Yelp_URL}
                  </a>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
            </div>
            <div className="col">
              <NoteField title={'Industry:'}>
                {note.data?.Industry ? (
                  note.data?.Industry
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Sub Industry:'}>
                {note.data?.Sub_Industry ? (
                  note.data?.Sub_Industry
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Topics to avoid:'}>
                {note.data?.TOPICS_TO_AVOID ? (
                  note.data?.TOPICS_TO_AVOID
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'More Sources:'}>
                {note.data?.More_Sources ? (
                  note.data?.More_Sources
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Hashtags:'}>
                {note.data?.Hashtags ? (
                  note.data?.Hashtags
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Instagram Link:'}>
                {note.data?.Instagram_URL ? (
                  <a
                    className="text-break"
                    href={note.data?.Instagram_URL}
                    target="__blank"
                    rel="noreferrer noopener">
                    {note.data?.Instagram_URL}
                  </a>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Twitter Link:'}>
                {note.data?.Twitter_URL ? (
                  <a
                    className="text-break"
                    href={note.data?.Twitter_URL}
                    target="__blank"
                    rel="n  oreferrer noopener">
                    {note.data?.Twitter_URL}
                  </a>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Account Status:'}>
                {note.data?.Account_Status ? (
                  note.data?.Account_Status
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Subscription Status:'}>
                {note.data?.Payment_Status ? (
                  note.data?.Payment_Status
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
              <NoteField title={'Subscription End Date:'}>
                {note.data?.Subscription_Ending ? (
                  note.data?.Subscription_Ending
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </NoteField>
            </div>
          </div>
          <section className="card-footer bg-transparent pd-y-15 pd-x-20">
            <p className="d-flex justify-content-end">
              {note.data?.Last_Activity_Time && (
                <i className="text-primary">
                  <strong>Last update:</strong>
                  <span className="ml-2">
                    {new Intl.DateTimeFormat('en-US', {
                      dateStyle: 'long',
                      timeStyle: 'short'
                    }).format(new Date(note.data?.Last_Activity_Time))}
                  </span>
                </i>
              )}
            </p>
          </section>
        </>
      )}
    </div>
  );
};

NoteContent.propTypes = {
  open: PropTypes.bool.isRequired
};

NoteContent.defaultProps = {
  open: true
};

export default NoteContent;
