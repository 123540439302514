import React, { useEffect, useState, forwardRef } from 'react';
import Select from 'react-select';
import ButtonLoad from './ButtonLoad';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { selectedClientSelector } from '../store/slices/selectedClient';
import { GET_GROUP_PROMPS, API_POSTS_EDIT, API_ASSETS } from '../utils';
import './Modal-calendar.css';
import { primaryColor } from '../theme/theme';

const AIToolsPost = forwardRef((props, ref) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [generatedMessage, setGeneratedMessage] = useState(null);
  const { selectedClient } = useSelector(selectedClientSelector);
  const [clientGroups, setClientGroups] = useState([]);
  const [groupPrompts, setGroupPrompts] = useState([]);
  const [modalDismiss, setModalDismiss] = useState();
  const [editableMessage, setEditableMessage] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [max, setMax] = useState(1);

  useEffect(() => {
    if (
      Array.isArray(selectedClient?.clientGroups) &&
      selectedClient?.clientGroups.length > 0
    ) {
      setClientGroups(selectedClient?.clientGroups);
    } else {
      setClientGroups([]);
    }
  }, [selectedClient]);

  useEffect(() => {
    const handleSetPrompts = async () => {
      const { data } = await axios.get(
        `${GET_GROUP_PROMPS}?group_id=${selectedGroup?.value}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const { prompts } = data;
      if (Array.isArray(prompts) && prompts.length > 0) {
        setGroupPrompts(prompts);
      } else {
        setGroupPrompts([]);
      }
    };
    if (selectedGroup) {
      handleSetPrompts();
    }
  }, [selectedGroup]);

  const handleSelectGroup = (option) => {
    setSelectedGroup(option);
  };

  const handleSelectPrompt = (option) => {
    setSelectedPrompt(option);
  };

  const reGenerateContent = async () => {
    if (selectedPrompt) {
      setEditableMessage(false);
      setIsGenerating(true);
      const response = await axios.post(
        'https://api.openai.com/v1/completions',
        {
          model: 'text-davinci-003',
          prompt: selectedPrompt?.label + 'in max 280 characters',
          max_tokens: 57,
          temperature: 0.5
        },
        {
          headers: {
            Authorization:
              'Bearer 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'
          }
        }
      );
      const generatedText = response?.data?.choices[0].text?.trim() || '';
      setGeneratedMessage(generatedText);
      setIsGenerating(false);
    }
  };
  const getKeyword = async (prompt) => {
    const response = await axios.post(
      'https://api.openai.com/v1/completions',
      {
        prompt: `Give me 2 keywords related with : "${selectedGroup?.label}" separated by commas. (I will use these keywords to generate get photos from pexels.com)`,
        model: 'text-davinci-003',
        max_tokens: 57,
        temperature: 0.5
      },
      {
        headers: {
          Authorization:
            'Bearer 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'
        }
      }
    );
    const words = response?.data?.choices[0]?.text?.trim() || '';
    return words.replace(/\n/g, '').replace(/,/g, '');
  };

  const regenerateImage = async () => {
    if (!selectedGroup?.label) {
      return;
    }
    setIsGeneratingImage(true);
    setEditableMessage(false);
    const keyword = await getKeyword(selectedPrompt?.label);
    const response = await axios
      .get(
        `https://api.pexels.com/v1/search?query=${
          selectedGroup?.label
        } ${keyword}&per_page=1&page=${Math.floor(Math.random() * max) + 1}`,
        {
          headers: {
            Authorization:
              '563492ad6f91700001000001fa0362890265465c96b7e6207bdb7893'
          }
        }
      )
      .catch((err) => {
        console.log(err);
        setIsGeneratingImage(false);
        setMax(1);
      });
    const image = response?.data?.photos[0]?.src?.medium || '';
    const maxResults = response?.data?.total_results || 1;
    setMax(maxResults);
    setGeneratedImage(image);
    setIsGeneratingImage(false);
  };

  const handleClose = () => {
    setSelectedGroup(null);
    setGroupPrompts([]);
    setGeneratedMessage(null);
    setSelectedPrompt(null);
    setIsGenerating(false);
    setLoading(false);
    setEditableMessage(false);
    setGeneratedImage(null);
    setIsGeneratingImage(false);
    setMax(1);
    modalDismiss.click();
  };
  return (
    <div className={!true ? 'disable' : ''}>
      <div
        className="modal fade"
        id="iatools"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addNewGroupLabel"
        aria-hidden="true"
        ref={ref}>
        <div
          id="modal-new-post"
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document">
          <div className={`modal-content tx-14 ${false ? 'disable' : ''}`}>
            <div className="modal-header">
              <div>
                <h6 className="modal-title" id="addNewGroupLabel">
                  IA Content - Regenerate this post message with the IA's help
                </h6>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}>
                <button
                  type="button"
                  style={{ marginLeft: 5, marginRight: 5 }}
                  className="btn btn-secondary tx-13"
                  data-dismiss="modal"
                  disabled={loading || isGenerating}
                  onClick={() => {
                    handleClose();
                  }}
                  ref={(button) => {
                    setModalDismiss(button);
                  }}>
                  Close
                </button>
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <ButtonLoad
                    state={loading || isGenerating}
                    style={{ marginLeft: 5 }}
                    btn={'btn-primary btn-success btn-publish-post'}
                    icon={'fa-check-square'}
                    text={' Confirm'}
                    callback={async () => {
                      setLoading(true);
                      if (!generatedMessage) {
                        handleClose();
                      }
                      let post = {
                        message: generatedMessage
                          ? generatedMessage
                          : props.post.title,
                        images: props.post.images,
                        schedule: props.post.start,
                        createdAt: props.post.createdAt,
                        socialNetworks: props.post.socialNetworks,
                        recurrentDates: [],
                        posted: false,
                        isScheduled: false,
                        createdBy: props.post.createdBy,
                        clientId: selectedClient.id,
                        postId: props.post.id,
                        isDraft: props.post.status === 'Draft' ? true : false
                      };
                      if (generatedImage) {
                        const uploadImage = async (bodyLoadImage) => {
                          await axios
                            .post(API_ASSETS, bodyLoadImage)
                            .then((response) => {
                              if (response['data']['Location']) {
                                let res =
                                  response['data']['Location'].substring(
                                    0,
                                    response['data']['Location'].lastIndexOf(
                                      '.'
                                    )
                                  ) + '.jpeg';
                                post.images = [
                                  {
                                    url: res,
                                    text:
                                      res.split('.com/')[1].split('.')[0] || '',
                                    originalUrl: generatedImage
                                  }
                                ];
                              }
                            })
                            .catch((error) => {
                              Swal.fire({
                                title: 'Error!',
                                text: 'The url is not valid!'
                              }).then((res) => {});
                            });
                        };
                        await uploadImage({
                          clientId: selectedClient.id,
                          urlFile: generatedImage
                        });
                      }

                      let start;
                      if (typeof post.schedule === 'object') {
                        start = new Date(post.schedule).getTime();
                        start = start / 1000;
                        start = Math.floor(start);
                        post.schedule = start;
                      }

                      await axios
                        .post(API_POSTS_EDIT, JSON.stringify(post))
                        .then(async (response) => {
                          await props.fetchPosts(props.queryDate);
                          handleClose();
                        })
                        .catch((error) => {
                          props.fetchPosts(props.queryDate);
                          if (error) {
                            Swal.fire({
                              title: 'error',
                              text: 'We have trouble scheduling the publication'
                            }).then((res) => {});
                          }
                          console.error(error);
                        });
                    }}
                  />
                </div>
                <button
                  type="button"
                  disabled={loading || isGenerating}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleClose();
                  }}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <form>
                    <div
                      className="card"
                      style={{
                        marginBottom: '30px',
                        marginTop: '30px',
                        marginLeft: '20px'
                      }}>
                      <div className="card-body">
                        <h5
                          className="card-title"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                          Select Group
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Choose the group you want to this post to focus on the
                          topic of its content
                        </h6>

                        <div className="form-group">
                          <Select
                            options={clientGroups.map((option, idx) => ({
                              value: option.id,
                              label: option.groupName
                            }))}
                            value={selectedGroup}
                            onChange={handleSelectGroup}
                            isDisabled={
                              loading || isGenerating || isGeneratingImage
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="card"
                      style={{
                        marginBottom: '30px',
                        marginTop: '30px',
                        marginLeft: '20px'
                      }}>
                      <div className="card-body">
                        <h5
                          className="card-title"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                          Select Prompt
                          {selectedGroup?.label
                            ? ' for ' + selectedGroup?.label + ' Group'
                            : null}
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Please choose a prompt to re-generate the content
                          message for the current post
                        </h6>
                        <div className="form-group">
                          <Select
                            options={groupPrompts.map((option, idx) => ({
                              value: option,
                              label: option
                            }))}
                            value={selectedPrompt}
                            onChange={handleSelectPrompt}
                            isDisabled={
                              loading || isGenerating || isGeneratingImage
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div
                    style={{
                      justifyContent: 'flex-end',
                      display: 'flex'
                    }}>
                    <button
                      type="button"
                      disabled={!selectedPrompt || loading || isGenerating}
                      style={{ marginTop: 0, marginBottom: 20 }}
                      className="btn btn-success tx-14"
                      onClick={() => {
                        reGenerateContent();
                      }}>
                      {isGenerating ? 'Generating...' : 'Generate Message'}
                    </button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="card"
                    style={{
                      marginBottom: '30px',
                      marginTop: '30px',
                      marginLeft: '20px',
                      minHeight: '400px',
                      height: '470px'
                    }}>
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}>
                        Re-Generated Post Content
                      </h5>
                      <div className="d-flex flex-row">
                        <div
                          style={{
                            overflowY: 'auto',
                            width: '100%'
                          }}>
                          <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                            Description{' '}
                            <button
                              style={{
                                border: 'none',
                                background: 'none',
                                marginLeft: '10px',
                                marginBottom: '5px',
                                color: 'gray',
                                outline: 'none'
                              }}
                              onClick={() => {
                                setEditableMessage(!editableMessage);
                              }}>
                              {editableMessage ? (
                                <i className="fas fa-lg fa-check" />
                              ) : (
                                <i className="fas fa-lg fa-edit" />
                              )}
                            </button>
                          </label>
                          <div className="flex-grow-1 flex-shrink-1">
                            <p className="mb-1 event-desc tx-gray-900 mg-b-40">
                              <textarea
                                type="text"
                                disabled={!editableMessage}
                                className="form-control"
                                placeholder="Enter your post message here"
                                value={generatedMessage || props.post.title}
                                style={{
                                  borderRadius: '3px',
                                  paddingLeft: '12px',
                                  paddingRight: '12px',
                                  paddingBottom: '10px',
                                  marginLeft: '5px',
                                  marginBottom: '20px',
                                  minHeight: '85px',
                                  maxHeight: '100px',
                                  width: '97%',
                                  resize: editableMessage ? 'vertical' : 'none',
                                  border: editableMessage
                                    ? '1px solid #ced4da'
                                    : 'none',
                                  backgroundColor: 'inherit'
                                }}
                                onChange={(e) => {
                                  setGeneratedMessage(e.target.value);
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={'content-vwimg'}>
                        {props?.post['images'] !== undefined ? (
                          <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                            Image
                          </label>
                        ) : (
                          []
                        )}
                        {props?.post['images'] !== undefined ? (
                          <div
                            className="event-imag"
                            style={{
                              backgroundImage: `url(${
                                generatedImage ||
                                props?.post['images'][0]['url']
                              })`,
                              minWidth: '150px',
                              minHeight: '220px',
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}>
                            {selectedGroup?.label && (
                              <button
                                style={{
                                  border: 'none',
                                  background: 'none',
                                  marginLeft: '10px',
                                  marginBottom: '15px',
                                  color: primaryColor,
                                  outline: 'none'
                                }}
                                onClick={() => {
                                  regenerateImage();
                                }}>
                                {isGeneratingImage ? (
                                  <i className="fas fa-lg fa-spinner fa-spin" />
                                ) : (
                                  <i className="fas fa-lg fa-sync-alt" />
                                )}
                              </button>
                            )}
                          </div>
                        ) : (
                          []
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AIToolsPost;
