import React from 'react';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { FaFacebook, FaGoogle, FaInstagram } from 'react-icons/fa';
import { API_SN_DELETE, API_GET_LINKS } from '../../../utils';
import './style.css';

const Links = ({ selectedClient, userInfo }) => {
  const history = useHistory();
  const [links, setLinks] = useState(null);

  // Function to disconnect social networks
  const disconnectSocialNetwork = async (network, clientId) => {
    Swal.fire({
      title: `Disconnect ${network}`,
      text: `Are you sure you want disconnect ${network}?`,
      showCloseButton: true,
      showCancelButton: true
    }).then(async (res) => {
      if (res.value) {
        await axios
          .delete(API_SN_DELETE, {
            data: {
              socialNetwork: network,
              id: clientId
            }
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Success!',
                text: 'Disconnected!',
                showCloseButton: true
              }).then((res) => {
                if (res.value) {
                  history.go(0);
                }
              });
            }
          });
      }
    });
  };

  useEffect(() => {
    if (!links) {
      const clientSocialNetworksLinks = async (clientId) => {
        try {
          const { Links } = await (
            await fetch(API_GET_LINKS + new URLSearchParams({ clientId }))
          ).json();
          if (links === null) {
            setLinks(Links);
          }
        } catch (error) {
          console.error('The link have an error', error);
        }
      };
      if (selectedClient.id && links === null) {
        clientSocialNetworksLinks(selectedClient.id);
      }
    }
  }, [selectedClient.id, links]);

  return (
    <div className="align-items-center social-networks-section">
      <ul className="list-unstyled profile-info-list align-items-center">
        <li>
          <FaFacebook size={20} className="text-gray-400" />
          {links?.Facebook_URL && selectedClient.socialnetworks.facebookPage ? (
            <a target="n_blank" href={links?.Facebook_URL}>
              {selectedClient.socialnetworks.facebookPage}
            </a>
          ) : (
            <span>
              {selectedClient.socialnetworks.facebookPage
                ? selectedClient.socialnetworks.facebookPage
                : 'None'}
            </span>
          )}
          {selectedClient.socialnetworks.facebookPage &&
          userInfo.role === 'superAdmin' ? (
            <button
              style={{
                borderWidth: '0',
                background: 'none',
                cursor: 'pointer',
                outline: 'inherit'
              }}
              onClick={() => {
                disconnectSocialNetwork('facebook', selectedClient.id);
              }}>
              <span className="badge badge-danger">disconnect</span>
            </button>
          ) : null}
        </li>
        <li>
          <FaGoogle size={20} className="text-gray-400" />
          {selectedClient.socialnetworks?.googleMyBusinessLocationName &&
          (links?.GMB_Google_2_URL || links?.Google_Business_Listing_URL) ? (
            <a
              target="n_blank"
              href={
                links?.GMB_Google_2_URL || links?.Google_Business_Listing_URL
              }>
              {selectedClient.socialnetworks.googleMyBusinessLocationName}
            </a>
          ) : (
            <span>
              {selectedClient.socialnetworks.googleMyBusinessLocationName
                ? selectedClient.socialnetworks.googleMyBusinessLocationName
                : 'None'}
            </span>
          )}
          {selectedClient.socialnetworks.googleMyBusinessLocationName &&
          userInfo.role === 'superAdmin' ? (
            <button
              style={{
                borderWidth: '0',
                background: 'none',
                cursor: 'pointer',
                outline: 'inherit'
              }}
              onClick={() => {
                disconnectSocialNetwork('googleMyBusiness', selectedClient.id);
              }}>
              <span className="badge badge-danger">disconnect</span>
            </button>
          ) : null}
        </li>
        <li>
          <FaInstagram size={20} className="icons" />
          {links?.Instagram_URL &&
          selectedClient.socialnetworks.instagramPage ? (
            <a target="n_blank" href={links?.Instagram_URL}>
              {selectedClient.socialnetworks.instagramPage}
            </a>
          ) : (
            <span>
              {selectedClient.socialnetworks.instagramPage
                ? selectedClient.socialnetworks.instagramPage
                : 'None'}
            </span>
          )}
          {selectedClient.socialnetworks.instagramPage &&
          userInfo.role === 'superAdmin' ? (
            <button
              style={{
                borderWidth: '0',
                background: 'none',
                cursor: 'pointer',
                outline: 'inherit'
              }}
              onClick={() => {
                disconnectSocialNetwork('instagram', selectedClient.id);
              }}>
              <span className="badge badge-danger">disconnect</span>
            </button>
          ) : null}
        </li>
      </ul>
    </div>
  );
};

export default Links;
