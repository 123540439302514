import React, { useState, useEffect, useCallback } from 'react';
import {
  API_CLEAN_NOTIFICATIONS,
  REACT_APP_API_NOTIFICATIONS
} from '../../utils/constants';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/slices/auth';
import { NotificationsGridItem } from './NotificationItem';
import LoadingSpinner from '../../pages/Calendar/GallerySection/StockImagesComponents/Partials/LoadingSpinner';
import './notificationsGrid.css';
import { Link, useLocation } from 'react-router-dom';

export const NotificationsGrid = () => {
  const [nofitications, setNofitications] = useState([]);
  const [totalNofitications, setTotalNofitications] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const { userInfo } = useSelector(authSelector);

  const handleTotal = () => {
    setTotalNofitications(totalNofitications - 1);
  };

  const getNotifications = useCallback(async () => {
    setIsLoad(true);
    const url = `${REACT_APP_API_NOTIFICATIONS}?email=${userInfo.email}`;
    const resp = await fetch(url);
    const data = await resp.json();

    const optionsTime = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      timeZone: 'America/Los_Angeles'
    };

    const result = data.map((nofitication) => {
      var createdAt = new Intl.DateTimeFormat('en-US', optionsTime).format(
        new Date(nofitication.createdAt)
      );
      return {
        id: nofitication.id,
        title: nofitication.title,
        is_read: nofitication.is_read,
        route: nofitication.route,
        createdAt: createdAt,
        author: nofitication.author
      };
    });
    setIsLoad(false);
    setNofitications(result);
    setTotalNofitications(result.length);
  }, [userInfo.email]);

  const cleanNotifications = async () => {
    setIsLoad(true);
    const { email } = userInfo;
    const url = API_CLEAN_NOTIFICATIONS;

    try {
      await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      getNotifications();
    } catch (error) {
      console.error('An error has ocurred');
    }
  };

  useEffect(() => {}, [isLoad]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  // Hook personalizado para obtener el path actual
  function useCurrentPath() {
    const location = useLocation(); // Obtiene el objeto location
    return location.pathname; // Retorna solo el path
  }

  return (
    <>
      <div className="dropdown dropdown-notification">
        <a
          href=""
          role="button"
          class="new mx-2 dropdown-link new-indicator"
          data-bs-toggle="dropdown">
          <i data-feather="bell"></i>
          {useCurrentPath() != '/logsuu' && totalNofitications >= 1 ? (
            <>
              <span>{totalNofitications}</span>
            </>
          ) : (
            ''
          )}
        </a>

        <div
          className="dropdown-menu dropdown-menu-right"
          style={{ zIndex: 1050, position: 'absolute' }}>
          <div className="dropdown-header">
            <div className="row">
              <div className="col-8">
                <Link to={`/logs`}>Notifications</Link>
              </div>
              <div
                className="col-4 text-right clear-all text-capitalize"
                style={{ pointerEvents: isLoad ? 'none' : 'auto' }}
                onClick={cleanNotifications}>
                Clear All
              </div>
            </div>
          </div>
          <div className="drowpown-body">
            {isLoad && <LoadingSpinner />}

            {!isLoad &&
              nofitications.map((nofitication) => (
                <NotificationsGridItem
                  key={nofitication.id}
                  notification={nofitication}
                  handleTotal={handleTotal}
                />
              ))}

            {nofitications.length < 1 && !isLoad ? (
              <div className="dropdown-item">
                <div className="media">
                  <div className="media-body mg-l-15">
                    <p> No messages</p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};
