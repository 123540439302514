import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../store/slices/auth';
import Swal from 'sweetalert2';

const LogOut = () => {
  const dispatch = useDispatch();

  const handleLogout = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Sign Out',
      text: 'Are you sure you want to log out?',
      showCloseButton: true,
      showCancelButton: true
    }).then((res) => {
      if (res.value) {
        dispatch(logoutUser());
      }
    });
  };

  return (
    <a href="" data-bs-toggle="tooltip" title="Sign out" onClick={handleLogout}>
      <i data-feather="log-out"></i>
    </a>
  );
};

export default LogOut;
