import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import './DateFilter.css'; // Importa tu archivo de estilos CSS

const DateFilter = ({
  onFilterChange,
  defaultDates,
  accountManagers,
  filter,
  loading,
  showReplied,
  setShowReplied,
  showGoogleReviews
}) => {
  const [startDate, setStartDate] = useState(
    new Date(defaultDates.startDate) || null
  );
  const [endDate, setEndDate] = useState(
    new Date(defaultDates.endDate) || null
  );

  const starsNames = [
    { id: '0', name: 'All' },
    { id: '1', name: 'One' },
    { id: '2', name: 'Two' },
    { id: '3', name: 'Three' },
    { id: '4', name: 'Four' },
    { id: '5', name: 'Five' }
  ];

  const statusList = [
    { id: '0', name: 'All' },
    { id: '1', name: 'Approved' },
    { id: '2', name: 'Not Approved' }
  ];

  const handleFilterChange = (val) => {
    if (startDate && endDate) {
      const filterObject = {
        ...filter,
        startDate: Math.floor(startDate.getTime() / 1000),
        endDate: Math.floor(endDate.getTime() / 1000)
      };
      onFilterChange(filterObject);
    } else {
      // Manejar el caso cuando no se han seleccionado ambas fechas
      console.error('Seleccione ambas fechas');
    }
    if (val?.filter === 'stars')
      onFilterChange((prevValue) => {
        return { ...filter, stars: val.value };
      });
    if (val?.filter === 'manager')
      onFilterChange((prevValue) => {
        return { ...filter, manager: val.value };
      });
    if (val?.filter === 'status')
      onFilterChange((prevValue) => {
        return { ...filter, status: val.value };
      });
  };
  const handleFilterClear = () => {
    onFilterChange({});
  };

  return (
    <div className="d-flex align-items-center justify-content-around px-4 py-3">
      <div className="d-flex align-items-center">
        <label className="mx-2 h-100" style={{ color: '#8392a5' }}>
          Date:
        </label>
        <DateTimePicker
          disabled={loading}
          onChange={(date) => setStartDate(date)}
          value={startDate}
          placeholder="Start Date"
          format="yyyy-MM-dd"
          clearIcon={null} // Deshabilita el botón de limpieza
          calendarIcon={null}
          className="custom-datetime-picker"
        />

        <label className="mx-2">-</label>
        <DateTimePicker
          disabled={loading}
          onChange={(date) => setEndDate(date)}
          value={endDate}
          placeholder="End Date"
          format="yyyy-MM-dd"
          clearIcon={null} // Deshabilita el botón de limpieza
          calendarIcon={null}
          className="custom-datetime-picker"
        />
      </div>

      <div className="d-flex align-items-center">
        <label className="mx-2" style={{ color: '#8392a5' }}>
          Stars:
        </label>{' '}
        <Select
          styles={customStyles}
          isDisabled={loading}
          defaultValue={{ value: '0', label: 'All' }}
          value={
            filter?.stars
              ? { value: filter?.stars, label: starsNames[filter?.stars].name }
              : { value: '0', label: 'All' }
          }
          onChange={(val) =>
            handleFilterChange({ filter: 'stars', value: val.value })
          }
          options={starsNames.map((option, idx) => ({
            value: option.id,
            label: option.name
          }))}
        />
      </div>

      <div className="d-flex align-items-center">
        <label className="mx-2" style={{ color: '#8392a5' }}>
          Account Manager:
        </label>
        <Select
          isDisabled={loading}
          styles={{
            ...customStyles,
            ...{
              container: (provided) => ({
                ...provided,
                width: '120px' // Ejemplo: ancho de 200px
              })
            }
          }}
          value={
            filter?.manager
              ? {
                  value: filter?.manager,
                  label: accountManagers.find(
                    (item) => item.id === filter?.manager
                  ).full_name
                }
              : { value: '0', label: 'All' }
          }
          onChange={(val) =>
            handleFilterChange({ filter: 'manager', value: val.value })
          }
          defaultValue={{ value: '0', label: 'All' }}
          options={accountManagers.map((option, idx) => ({
            value: option.id,
            label: option.full_name
          }))}
        />
      </div>

      {/* <label className="mx-2" style={{ color: '#8392a5', marginLeft: '15px', marginRight: '15px' }}>
        Filter by Status
      </label>
      <Select
        style={{ marginLeft: '15px' }}
        isDisabled={loading}
        styles={{ ...customStyles, ...{ container: (provided) => ({
          ...provided,
          width: '130px' // Ejemplo: ancho de 200px 
        }) } }}
        defaultValue={{ value: '0', label: '--' }}
        value={defaultDates.stars}
        onChange={(val) =>
          handleFilterChange({ filter: 'status', value: val.value })
        }
        options={[
          { id: '1', name: 'Pending by Account Owner' },
          { id: '2', name: 'Pending (scheduled)' },
          { id: '3', name: 'Denied by Client' },
          { id: '4', name: 'Sent By AI' },
          { id: '5', name: 'Pending by scheduler' },
          { id: '6', name: 'Pending by Client' },
          { id: '7', name: 'Replied by Client' },
        ].map((option, idx) => ({ value: option.id, label: option.name }))}
      /> */}

      <div className="d-flex align-items-center">
        <label className="mx-2" style={{ color: '#8392a5' }}>
          Status:
        </label>
        <Select
          isDisabled={loading}
          styles={{
            ...customStyles,
            ...{
              container: (provided) => ({
                ...provided,
                width: '130px'
              })
            }
          }}
          defaultValue={{ value: '0', label: 'All' }}
          value={
            filter?.status
              ? {
                  value: filter?.status,
                  label: statusList.find((item) => item.id === filter?.status)
                    .name
                }
              : { value: '0', label: 'All' }
          }
          onChange={(val) =>
            handleFilterChange({ filter: 'status', value: val.value })
          }
          options={statusList.map((option, idx) => ({
            value: option.id,
            label: option.name
          }))}
        />
      </div>

      <div className="d-flex align-items-center">
        <button
          className="btn btn-sm pd-x-15 btn-primary mg-l-5"
          disabled={loading}
          onClick={handleFilterChange}>
          Filter
        </button>
        {showGoogleReviews && (
          <label
            className="mx-2"
            className={`btn btn-sm pd-x-15 btn-primary mg-l-5 ${
              loading ? 'disabled' : ''
            }`}
            onClick={() => setShowReplied(!showReplied)}>
            {showReplied ? 'Hide Replied' : 'Show Replied'}
          </label>
        )}
        {filter?.startDate ||
        filter?.endDate ||
        filter?.stars ||
        filter?.manager ||
        filter?.status ? (
          <button
            className="btn btn-sm pd-x-15 btn-secondary mg-l-5"
            disabled={loading}
            onClick={handleFilterClear}>
            Clear
          </button>
        ) : null}
      </div>
    </div>
  );
};
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '60px'
  }),
  control: (provided) => ({
    ...provided,
    maxHeight: '25px',
    minHeight: '8px'
  }),
  menu: (provided) => ({
    ...provided
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3b5998' : 'white'
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '2px',
    paddingLeft: '1px'
  }),

  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  })
};

export default DateFilter;
