import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { SearchIcon } from '../../Icons';
import { API_GET_CS_LIST } from '../../utils/constants';
function SearchBar({ onSelectClient }) {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(API_GET_CS_LIST);
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const getContentStrategies = () => {
    return clients.map((strategy) => ({
      label: strategy.strategyName,
      value: strategy.id
    }));
  };
  const handleChangeClient = (x) => {
    console.log(x);
    onSelectClient(x);
  };
  const searchStyle = {
    control: (_, { selectProps: { width } }) => ({
      border: 'none'
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    input: (provided) => ({
      marginTop: '0.5em'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#c0ccda', // Color personalizado del placeholder
      fontSize: '0.875rem', // Tamaño de la fuente del placeholder (opcional)
      marginLeft: '-8px'
    })
  };
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill="rgb(131, 146, 165)"
          d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
        />
      </svg>
      <Select
        styles={searchStyle}
        className="form-control"
        type="search"
        placeholder="Search images"
        onChange={(client) => handleChangeClient(client)}
        options={getContentStrategies()}
        hideSelectedOptions={true}
        controlShouldRenderValue={false}
      />
    </>
  );
}

export default SearchBar;
