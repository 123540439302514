import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { syncInstagramPage } from './services';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 110,
    marginTop: 30
  },
  formControl: {
    margin: '2em 2em',
    minWidth: 240
  },
  textField: {
    width: '25em',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#97a3b9'
      },
      '&:hover fieldset': {
        borderColor: '#97a3b9'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#97a3b9'
      }
    }
  },
  dialogTitle: {
    fontSize: '12px'
  }
}));

const InstagramSyncModal = ({ open, handleModal }) => {
  const [pageId, setPageId] = useState('');
  const classes = useStyles();

  const handleSync = (e) => {
    e.preventDefault();
    syncInstagramPage(pageId);
    handleModal(open);
  };

  useEffect(() => {
    if (!open) {
      setPageId('');
    }
    return () => {
      setPageId('');
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={() => handleModal(open)}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { overflowY: 'visible' } }}>
      <DialogTitle className={classes.dialogTitle}>
        Connect An Instagram Account
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" direction="row">
          {/* Content Modal */}
          <FormControl className={classes.formControl} variant="outlined">
            <div className="mb-2">
              <span style={{ color: '#97a3b9', fontSize: '0.9rem' }}>
                Facebook Page ID
              </span>
            </div>
            <TextField
              onChange={(e) => setPageId(e.target.value)}
              id="outlined-uncontrolled"
              name="pageId"
              value={pageId}
              type="text"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleModal(open)}
          variant="outlined"
          color="inherit"
          style={{ marginRight: 15, marginBottom: 10 }}>
          Close
        </Button>
        <Button
          onClick={handleSync}
          variant="outlined"
          color="primary"
          style={{ marginRight: 15, marginBottom: 10 }}>
          Sync
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstagramSyncModal;
