export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1);
};

export const timeZones = [
  { id: 1, name: 'Eastern Daylight Time - (GMT-4)' },
  { id: 2, name: 'Central Daylight Time - (GMT-5)' },
  { id: 3, name: 'Mountain Daylight Time - (GMT-6)' },
  { id: 4, name: 'Mountain Standard Time - (GMT-7)' },
  { id: 5, name: 'Pacific Daylight Time - (GMT-7)' },
  { id: 6, name: 'Alaska Daylight Time - (GMT-8)' },
  { id: 7, name: 'Hawaii-Aleutian Standard Time - (GMT-10)' }
];

export const daysOrder = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7
};

export const BUSINESSHOURS = [
  {
    value: 1,
    label: '1 AM'
  },
  {
    value: 2,
    label: '2 AM'
  },
  {
    value: 3,
    label: '3 AM'
  },
  {
    value: 4,
    label: '4 AM'
  },
  {
    value: 5,
    label: '5 AM'
  },
  {
    value: 6,
    label: '6 AM'
  },
  {
    value: 7,
    label: '7 AM'
  },
  {
    value: 8,
    label: '8 AM'
  },
  {
    value: 9,
    label: '9 AM'
  },
  {
    value: 10,
    label: '10 AM'
  },
  {
    value: 11,
    label: '11 AM'
  },
  {
    value: 12,
    label: '12 PM'
  },
  {
    value: 13,
    label: '1 PM'
  },
  {
    value: 14,
    label: '2 PM'
  },
  {
    value: 15,
    label: '3 PM'
  },
  {
    value: 16,
    label: '4 PM'
  },
  {
    value: 17,
    label: '5 PM'
  },
  {
    value: 18,
    label: '6 PM'
  },
  {
    value: 19,
    label: '7 PM'
  },
  {
    value: 20,
    label: '8 PM'
  },
  {
    value: 21,
    label: '9 PM'
  },
  {
    value: 22,
    label: '10 PM'
  },
  {
    value: 23,
    label: '11 PM'
  },
  {
    value: 0,
    label: '12 AM'
  }
];

export const accountManagers = [
  {
    id: '0',
    full_name: 'All'
  },
  {
    id: '3359678000000161021',
    full_name: 'Stefan Singer'
  },
  {
    id: '3359678000000189001',
    full_name: 'Claire Sabando'
  },
  {
    id: '3359678000002719001',
    full_name: 'Dj Hargrove'
  },
  {
    id: '3359678000003451001',
    full_name: 'Robin Lincoln-Codjoe'
  },
  {
    id: '3359678000043808001',
    full_name: 'Esther Rowe'
  },
  {
    id: '3359678000046853103',
    full_name: 'Daniel Dominguez'
  },
  {
    id: '3359678000057925001',
    full_name: 'Paula Halfond'
  },
  {
    id: '3359678000094902026',
    full_name: 'Jose Valera'
  },
  {
    id: '3359678000157782001',
    full_name: 'Lauren  Turkovic'
  },
  {
    id: '3359678000163193001',
    full_name: 'Michael Ognar-Pettersson'
  },
  {
    id: '3359678000163912001',
    full_name: 'Heather DeMorris'
  },
  {
    id: '3359678000164337001',
    full_name: 'Phillippa Sirdar'
  },
  {
    id: '3359678000176335001',
    full_name: 'Deborah Moxam'
  },
  {
    id: '3359678000177646027',
    full_name: 'DeAnnie Baker'
  },
  {
    id: '3359678000177656001',
    full_name: 'Jorge Ramirez'
  },
  {
    id: '3359678000182709001',
    full_name: 'Christiana Daskalakis'
  },
  {
    id: '865239221',
    full_name: 'Zachary Marsh'
  },
  {
    id: '865239223',
    full_name: 'Emily Betancourt'
  }
];
