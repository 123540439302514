import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import cx from 'classnames';
import axios from 'axios';
import {
  API_NEWCLIENT,
  API_KEY,
  API_CLIENTS_NAME,
  API_GET_PLANS
} from '../../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { generateContent } from '../../../store/slices/generalState';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import './style.css';
import BusinessHours from './BusinessHours';
import Swal from 'sweetalert2';
import { setSelectedClient } from '../../../store/slices/selectedClient';
import Switch from '@mui/material/Switch';
import Links from '../Links';

export const daysOrder = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7
};

const AboutOnboarding = ({
  // showModal,
  // handleClose,
  callbackFromParent,
  defaultData = {}
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currStep, setCurrStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    clientName: '', // String required
    timeZone: '', // String required
    serviceArea: [], // list of Strings optional
    secondaryServiceArea: [],
    description: '', // String optional
    businessHours: {
      // Object with keys required and values for business hours as strings with format 'number/number' or just '/'
      monday: '9/18',
      tuesday: '9/18',
      wednesday: '9/18',
      thursday: '9/18',
      friday: '9/18',
      saturday: '9/18',
      sunday: '9/18'
    },
    mergeFields: {
      // Object with keys required and values as strings
      '~Address': '',
      '~Phone': '',
      '~Website': '',
      '~Email': ''
    },
    industry: '', // String required
    contentStrategy: '', // String required
    primaryService: [], // list of Strings optional
    additionalService: [], // list of Strings optional
    attributes: [], // list of Strings optional
    promotions: [], // list of Strings optional
    bestTimes: {
      // Object required with days as keys and strings as values (those strings have a format like '00' )
      Monday: '07',
      Tuesday: '07',
      Wednesday: '07',
      Thursday: '07',
      Friday: '07',
      Saturday: '07',
      Sunday: '07'
    },
    holidays: [], // list of strings optional
    igTags: [], // list of strings optional
    fbTags: [], // list of strings optional
    negativeKeywords: [], // list of strings required
    galleries: {
      //object with those keys and values optionals but if enabled the url is required
      stockPhotos: {
        enabled: true
      },
      appLibrary: {
        enabled: false
      }
    },
    logoUrl: '', // string optional
    brand: {
      // object optional
      primaryColor: '#3B5998',
      secondaryColor: '#8392A5'
    },
    plan: '',
    automaticReviews: false
  };

  const userInfo = useSelector((state) => state.auth.userInfo);
  const timeZones = [
    { value: 1, label: 'Eastern Daylight Time - (GMT-4)' },
    { value: 2, label: 'Central Daylight Time - (GMT-5)' },
    { value: 3, label: 'Mountain Daylight Time - (GMT-6)' },
    { value: 4, label: 'Mountain Standard Time - (GMT-7)' },
    { value: 5, label: 'Pacific Daylight Time - (GMT-7)' },
    { value: 6, label: 'Alaska Daylight Time - (GMT-8)' },
    { value: 7, label: 'Hawaii-Aleutian Standard Time - (GMT-10)' }
  ];
  const customMultiSelectorStyle = {
    dropdownIndicator: () => ({
      display: 'none'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    control: (provided) => ({
      ...provided,
      width: '100%'
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 200
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#3B5998',
      color: 'black',
      borderRadius: '0px'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      '&:hover': {
        backgroundColor: '#D9D9D9',
        color: 'black'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: 75,
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: 'darkgray lightgray',
      '&::-webkit-scrollbar': {
        width: '8px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgray',
        borderRadius: '8px'
      }
    })
  };

  const validationSchema = yup.object().shape({
    clientName: yup.string().required('Client name is required'),
    timeZone: yup.string().required('Time zone is required'),
    serviceArea: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A service area has an invalid format'
          )
      ),
    secondaryServiceArea: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A secondary service area has an invalid format'
          )
      ),
    // .min(1, 'At least one service area is required'),
    description: yup.string(),
    businessHours: yup.object().shape({
      monday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      tuesday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      wednesday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      thursday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      friday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      saturday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        }),
      sunday: yup
        .string()
        .matches(
          /^((?:2[0-4]|1[0-9]|[0-9])\/(?:2[0-4]|1[0-9]|[0-9])|\/)$/,
          'Invalid format hour should be a number between 0-24'
        )
        .test('valid-hours', 'Invalid hours range', (value) => {
          if (value === '/') {
            return true;
          }
          const [start, end] = value.split('/');
          return parseInt(start, 10) < parseInt(end, 10);
        })
    }),
    mergeFields: yup.object().shape({
      '~Address': yup.string(),
      '~Phone': yup.string(),
      '~Website': yup.string(),
      '~Email': yup.string(),
      '~City': yup.string(),
      '~Name': yup.string(),
      '~BestDish': yup.string(),
      '~BestService': yup.string(),
      '~BestProduct': yup.string()
    }),
    industry: yup.string(),
    contentStrategy: yup.string(),
    primaryService: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s',.~]+$/,
            'A primary has an invalid format'
          )
      ),
    // .min(1, 'At least one primary service is required'),
    additionalService: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s',.~]+$/,
            'An additional service has an invalid format'
          )
      ),
    attributes: yup.array().of(
      yup.string()
      // .matches(
      //   /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
      //   'An attribute has an invalid format'
      // )
    ),
    promotions: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A promotion has an invalid format'
          )
      ),
    bestTimes: yup.object().shape({
      Monday: yup.string().required(),
      Tuesday: yup.string().required(),
      Wednesday: yup.string().required(),
      Thursday: yup.string().required(),
      Friday: yup.string().required(),
      Saturday: yup.string().required(),
      Sunday: yup.string().required()
    }),
    holidays: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'Invalid format'
          )
      ),
    igTags: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A tag has an invalid format'
          )
      ),
    fbTags: yup
      .array()
      .of(
        yup
          .string()
          .matches(
            /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
            'A tag has an invalid format'
          )
      ),
    negativeKeywords: yup.array().of(
      yup.string()
      // .matches(
      //   /^(?:[a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*~)?[a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]+$/,
      //   'A negative keyword has an invalid format'
      // )
    ),
    galleries: yup.object().shape({
      stockPhotos: yup.object().shape({
        enabled: yup.boolean()
      }),
      appLibrary: yup.object().shape({
        enabled: yup.boolean()
      }),
      yelp: yup.object().shape({
        enabled: yup.boolean()
      }),
      gmb: yup.object().shape({
        enabled: yup.boolean()
      }),
      dropbox: yup.object().shape({
        enabled: yup.boolean()
      })
    }),
    logoUrl: yup.string(),
    brand: yup.object().shape({
      primaryColor: yup.string(),
      secondaryColor: yup.string()
    }),
    plan: yup.string(),
    automaticReviews: yup.boolean()
  });

  const editing = Object.keys(defaultData).length > 0;

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props} className="selected-option">
      {children}
      <div className="selected-logo">
        <Connected />
      </div>
    </components.SingleValue>
  );

  const Option = (props) => (
    <components.Option {...props}>
      <div className="country-option">
        {props.data.label}
        <Disconnected />
      </div>
    </components.Option>
  );

  const Connected = () => (
    <img src="../assets/img/Connected.png" alt="connected" />
  );

  const Disconnected = () => (
    <img src="../assets/img/Disconnected.png" alt="disconnected" />
  );

  const ContentStep = ({ current = false, title, children }) => {
    return (
      <>
        {current && (
          <div>
            <section
              id="wizard1-p-0"
              className={cx('body', {
                current,
                'd-none': !current
              })}>
              {children}
            </section>
          </div>
        )}
      </>
    );
  };
  const Step = ({ children, step, current }) => <></>;

  const BusinessInformation = ({
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched
  }) => {
    const [serviceAreas, setServiceAreas] = useState([]);
    const [secondaryServiceAreas, setSecondaryServiceAreas] = useState([]);
    const [plans, setPlans] = useState([]);
    const [gettingPlans, setGettingPlans] = useState(false);

    const getPlans = async () => {
      setGettingPlans(true);

      const equalsCheck = (a, b) => JSON.stringify(a) === JSON.stringify(b);

      const order = [
        'Legacy Plan',
        'Starter Plan',
        'Pro Plan',
        'Plus Plan',
        'Elite Plan'
      ];

      try {
        const response = await axios.get(`${API_GET_PLANS}`);
        const plans = response.data;

        const formattedPlans = plans.map((plan) => ({
          ...plan,
          value: plan.PK,
          label: plan.SK
        }));

        const sortedPlans = formattedPlans.sort(
          (a, b) => order.indexOf(a.label) - order.indexOf(b.label)
        );

        if (!equalsCheck(plans, sortedPlans) || values?.plan === '') {
          setPlans(sortedPlans);
        }
      } catch (e) {
        console.error(e);
        return [];
      } finally {
        setGettingPlans(false);
      }
    };

    useEffect(() => {
      if (plans.length === 0 && !gettingPlans) {
        getPlans();
      }
    }, [plans, values?.plan]);

    return (
      <>
        <div className="bi-main-container">
          <div className="bi-column-1">
            <div
              className=""
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                color: '#8392A5',
                fontWeight: '450'
              }}>
              Business Name
            </div>
            <div
              className="mt-2"
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column'
              }}>
              <input
                id="clientName"
                type="text"
                required
                placeholder="Client Name"
                value={values?.clientName}
                onChange={handleChange}
                className={`w-full p-2 md-2 border border-black rounded`}
                onBlur={handleBlur}
              />
              {touched?.clientName && errors?.clientName && (
                <div className="mt-1 text-xs">{errors?.clientName}</div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '20px'
              }}
              className="font">
              Select Time Zone:
            </div>
            <div
              onBlur={() => {
                setFieldTouched('timeZone', true);
              }}
              style={{
                width: '100%'
              }}>
              <Select
                required
                name="timeZone"
                options={timeZones}
                className="basic-single"
                value={timeZones.find(
                  (option) => option.label === values?.timeZone
                )}
                onChange={(selectedOption) =>
                  setFieldValue('timeZone', selectedOption.label)
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.timeZone && errors?.timeZone
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.timeZone && touched?.timeZone && (
                <div className="invalid-feedback d-inline">
                  {errors?.timeZone}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '20px'
              }}
              className="font">
              Select Plan:
            </div>
            <div
              onBlur={() => {
                setFieldTouched('plan', true);
              }}
              style={{
                width: '100%'
              }}>
              <Select
                id="plan"
                options={plans}
                value={plans.find((option) => option.value === values?.plan)}
                onChange={async (selectedOption) => {
                  setFieldValue('plan', selectedOption.value);
                }}
                components={{
                  Option,
                  SingleValue
                }}
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    display: 'flex',
                    alignItems: 'center'
                  }),
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.plan && errors?.plan
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.plan && touched?.plan && (
                <div className="invalid-feedback d-inline">{errors?.plan}</div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '20px'
              }}
              className="font">
              Location City / Primary Service Area
            </div>
            <div className="font mt-2" style={{ width: '100%' }}>
              <CreatableSelect
                id="serviceArea"
                name="serviceArea"
                isMulti
                isClearable={false}
                value={
                  serviceAreas?.length > 0
                    ? serviceAreas
                    : values?.serviceArea?.map((servA, index) => {
                        return { value: index, label: servA };
                      })
                }
                noOptionsMessage={() => 'Type a service area'}
                onChange={(selectedOption) => {
                  setServiceAreas(
                    selectedOption ? selectedOption.serviceArea : []
                  );
                  setFieldValue(
                    'serviceArea',
                    selectedOption
                      ? selectedOption?.map((servA) => servA.label)
                      : []
                  );
                }}
                options={serviceAreas}
                placeholder="Type a service area"
                onBlur={() => {
                  setFieldTouched('serviceArea', true);
                }}
                styles={{
                  ...customMultiSelectorStyle,
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.serviceArea && errors?.serviceArea
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.serviceArea && touched?.serviceArea && (
                <div className="invalid-feedback d-inline">
                  {errors?.serviceArea}
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '20px'
              }}
              className="font">
              Secondary Service Area
            </div>
            <div className="font mt-2" style={{ width: '100%' }}>
              <CreatableSelect
                id="secondaryServiceArea"
                name="secondaryServiceArea"
                isMulti
                isClearable={false}
                value={
                  secondaryServiceAreas?.length > 0
                    ? secondaryServiceAreas
                    : values?.secondaryServiceArea?.map((servA, index) => {
                        return { value: index, label: servA };
                      })
                }
                noOptionsMessage={() => 'Type a secondary service area'}
                onChange={(selectedOption) => {
                  setSecondaryServiceAreas(
                    selectedOption ? selectedOption.secondaryServiceArea : []
                  );
                  setFieldValue(
                    'secondaryServiceArea',
                    selectedOption
                      ? selectedOption?.map((servA) => servA.label)
                      : []
                  );
                }}
                options={secondaryServiceAreas}
                placeholder="Type a secondary service area"
                onBlur={() => {
                  setFieldTouched('secondaryServiceArea', true);
                }}
                styles={{
                  ...customMultiSelectorStyle,
                  control: (provided) => ({
                    ...provided,
                    ...(touched?.secondaryServiceArea &&
                    errors?.secondaryServiceArea
                      ? {
                          borderColor: 'red'
                        }
                      : {})
                  })
                }}
              />
              {errors?.secondaryServiceArea &&
                touched?.secondaryServiceArea && (
                  <div className="invalid-feedback d-inline">
                    {errors?.secondaryServiceArea}
                  </div>
                )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '20px'
              }}
              className="font">
              Description
            </div>
            <textarea
              id="description"
              onChange={handleChange}
              value={values?.description}
              onBlur={handleBlur}
              className={`w-full p-2 md-2 mt-2 border border-black rounded ${
                touched?.description && errors?.description ? 'is-invalid' : ''
              }`}
              rows={3}
              placeholder="Type here..."
              style={{
                width: '100%',
                height: '80px',
                resize: 'none',
                borderRadius: '6px',
                padding: '10px',
                outline: 'none',
                color: '#3B5998',
                fontWeight: '500'
              }}
            />
            {errors?.description && touched?.description && (
              <div className="invalid-feedback d-inline">
                {errors?.description}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: '20px'
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '10px'
                }}
                className="font">
                Automatic Reviews
              </div>
              <div>
                <Switch
                  checked={defaultData?.automaticReviews}
                  value={values?.automaticReviews}
                  onChange={async (selectedOption) => {
                    setFieldValue('automaticReviews', selectedOption.value);
                  }}
                  size="small"
                  color={'primary'}
                />
              </div>
            </div>
            {errors?.automaticReviews && touched?.automaticReviews && (
              <div className="invalid-feedback d-inline">
                {errors?.automaticReviews}
              </div>
            )}
          </div>

          <div className="bi-column-2">
            <BusinessHours
              values={values}
              setFieldValue={(field, value) => {
                setFieldValue(field, value);
              }}
              errors={errors}
              touched={touched}
              onBlur={handleBlur}
            />
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                paddingBottom: '8px'
              }}
              className="font mt-2">
              Tags and Mergefields
            </div>

            <div
              style={{
                padding: '0 10px', // Padding a los lados
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              {['~Address', '~Phone', '~Website', '~Email'].map((field) => (
                <div
                  key={field}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '4px 0'
                  }}>
                  <p className="font">{field}</p>
                  <input
                    id={`mergeFields.${field}`}
                    type="text"
                    onChange={handleChange}
                    value={values?.mergeFields[field]}
                    style={{
                      width: '300px', // Width aumentado
                      padding: '0.5rem', // Padding dentro del input
                      height: 'calc(1em + 0.9375rem + 2px)'
                    }}
                    className={`border border-black rounded ${
                      touched?.mergeFields &&
                      touched?.mergeFields[field] &&
                      errors?.mergeFields &&
                      errors?.mergeFields[field]
                        ? 'is-invalid'
                        : ''
                    }`}
                    onBlur={handleBlur}
                  />
                  {touched?.mergeFields &&
                    errors?.mergeFields &&
                    touched?.mergeFields[field] &&
                    errors?.mergeFields[field] && (
                      <div
                        className="invalid-feedback"
                        style={{ textAlign: 'end' }}>
                        {errors?.mergeFields[field]}
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderContentSteps = ({ ...props }) => {
    return steps.map((step, index) => {
      return (
        <ContentStep
          key={index}
          current={currStep === index + 1}
          title={step.title}>
          {step.content({ ...props })}
        </ContentStep>
      );
    });
  };

  const steps = [
    {
      title: 'Business Information',
      content: ({ ...props }) => <BusinessInformation {...props} />
    }
  ];
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const handlePut = async (data = {}) => {
    const newClientSave = async (data) => {
      const newClientUrl = API_NEWCLIENT;
      setLoading(true);
      let newClientName = data.clientName.replace(/\s+/g, ' ');
      newClientName = newClientName.replace(/^\s+|\s+$/g, '');
      newClientName = newClientName.replace(/’/g, "'");

      const clientData = {
        ...data,
        id: defaultData?.id,
        clientName: newClientName,
        automaticReviews: defaultData?.automaticReviews
      };

      await axios({
        method: 'post',
        url: newClientUrl,
        data: clientData
      })
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire({
              type: 'success',
              title: 'Success!',
              text: `The client was ${
                clientData.id ? 'updated' : 'created'
              } successfully.`
            }).then(async () => {
              setCurrStep(1);
              setLoading(false);
              // handleClose();
              someFn(clientData);
              if (clientData?.id) {
                const clData = await axios.get(
                  `${API_CLIENTS_NAME}${clientData.clientName}`,
                  {
                    headers: {
                      'X-API-KEY': API_KEY,
                      Authorization: `bearer ${userInfo.accessToken}`
                    }
                  }
                );
                let newUrl = clData?.data?.clientName
                  .replace(/\s+/g, '%20')
                  .replace(/\//g, '%2F')
                  .replace(/'/g, '%1D')
                  .replace(/’/g, '%1E');
                history.push(`/client/${newUrl}`);

                if (clData.data) {
                  dispatch(setSelectedClient(clData.data));
                }
                return;
              }
              const clData = await axios.get(
                `${API_CLIENTS_NAME}${clientData.clientName}`,
                {
                  headers: {
                    'X-API-KEY': API_KEY,
                    Authorization: `bearer ${userInfo.accessToken}`
                  }
                }
              );

              let newUrl = clientData.clientName
                .replace(/\s+/g, '%20')
                .replace(/\//g, '%2F')
                .replace(/'/g, '%1D')
                .replace(/’/g, '%1E');
              history.push(`/calendar/${newUrl}`);

              if (clData.data) {
                dispatch(setSelectedClient(clData.data));
                dispatch(generateContent());
              }
            });
          }
        })
        .catch(function (xhr, ajaxOptions, thrownError) {
          Swal.fire({
            type: 'error',
            title: 'Error!',
            text: 'The client was not created successfully due an error.'
          }).then(() => {
            // TODO: Handle error
          });
        });
    };

    await newClientSave(data);
  };
  const someFn = (listInfo) => {
    callbackFromParent(listInfo);
  };

  return (
    <Formik
      onSubmit={(data) => {
        handlePut(data);
      }}
      validateOnBlur={true}
      initialValues={
        editing
          ? {
              ...defaultData,
              ...(!defaultData.businessHours?.monday
                ? { businessHours: initialValues.businessHours }
                : {}),
              ...(!defaultData?.brand?.primaryColor
                ? { brand: initialValues.brand }
                : {})
            }
          : null || initialValues
      }
      validationSchema={validationSchema}>
      {(props) => {
        const { handleSubmit, setFieldTouched, values } = props;

        const buttonLabel = loading ? '' : 'Update data';
        return (
          <>
            <div className="card" id="posts-section">
              <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                <h6 className="tx-uppercase tx-semibold mg-b-0">About</h6>
                <Links selectedClient={defaultData} userInfo={userInfo} />
                <div className="actions clearfix">
                  <button
                    type={currStep === steps.length + 1 ? 'button' : 'button'}
                    className={cx('btn', 'button', [
                      { laststep: currStep === steps.length }
                    ])}
                    disabled={loading}
                    onClick={() => {
                      const fieldsToValidate = [
                        ...(currStep === 1
                          ? [
                              'clientName',
                              'timeZone',
                              'automaticReviews',
                              'serviceArea',
                              'secondaryServiceArea',
                              'description',
                              'businessHours.monday',
                              'businessHours.tuesday',
                              'businessHours.wednesday',
                              'businessHours.thursday',
                              'businessHours.friday',
                              'businessHours.saturday',
                              'businessHours.sunday',
                              'mergeFields.~Address',
                              'mergeFields.~Phone',
                              'mergeFields.~Website',
                              'mergeFields.~Email',
                              'mergeFields.~City'
                            ]
                          : []),
                        ...(currStep === 2
                          ? [
                              'industry',
                              'contentStrategy',
                              'primaryService',
                              'additionalService',
                              'attributes',
                              'promotions',
                              'bestTimes.Monday',
                              'bestTimes.Tuesday',
                              'bestTimes.Wednesday',
                              'bestTimes.Thursday',
                              'bestTimes.Friday',
                              'bestTimes.Saturday',
                              'bestTimes.Sunday',
                              'holidays',
                              'igTags',
                              'fbTags',
                              'negativeKeywords',
                              'plan'
                            ]
                          : []),
                        ...(currStep === 3 ? [] : [])
                      ];

                      const subFormErrors = {};

                      fieldsToValidate.forEach((field) => {
                        try {
                          validationSchema.validateSyncAt(field, values);
                        } catch (error) {
                          subFormErrors[field] = error.message;
                          setFieldTouched(field, true);
                        }
                      });

                      if (Object.keys(subFormErrors).length === 0) {
                        if (currStep === steps.length) {
                          handleSubmit();
                        } else {
                          setCurrStep((prevStep) => prevStep + 1);
                        }
                      } else {
                        // warning to the user that some fields have errors
                      }
                    }}>
                    {buttonLabel}
                    {loading && (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      />
                    )}
                  </button>
                </div>
              </div>
              <div className="card-body pd-25">
                <div>
                  <Form
                    autoComplete="off"
                    onKeyDown={onKeyDown}
                    className="needs-validation"
                    noValidate>
                    <div>
                      {steps.map((step, index) => {
                        return (
                          <Step
                            key={index}
                            step={index + 1}
                            current={currStep === index + 1}>
                            {step.title}
                          </Step>
                        );
                      })}
                      <div>
                        <RenderContentSteps {...props} />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AboutOnboarding;
