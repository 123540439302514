import React from 'react';
import Image from '../../components/StrategyImages/Image';

function ImageGallery({
  isPage = false,
  images,
  onDeleteImage,
  strategyName = null
}) {
  return (
    <>
      <div className="row row-xs">
        {images.length > 0 ? (
          images.map((img, idx) => {
            return (
              <div className="col-sm-6 col-lg-3" key={idx + img?.SK}>
                <Image
                  onDeleteImage={onDeleteImage}
                  style={{
                    width: '18vw',
                    marginLeft: '20px',
                    marginRight: '20px'
                  }}
                  img={img}
                />
              </div>
            );
          })
        ) : (
          <>
            <div className="d-flex flex-wrap gap-3 justify-content-around">
              {Array.from({ length: 12 }).map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    width: '18vw',
                    minHeight: '16vw',
                    backgroundColor: '#E7E7E7',
                    borderRadius: '5px'
                  }}></div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ImageGallery;
