import React from 'react';
import { SearchIcon } from '../../Icons';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clientsSelector } from '../../store/slices/clients';
import NewClientOnboarding from './NewClientOnboarding';

const NewClient = () => {
  const history = useHistory();
  const { customerList } = useSelector(clientsSelector);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      color: state.selectProps.menuColor,
      padding: 10,
      overflow: 'hidden',
      backgroundColor: 'white', // Fondo blanco para el menú
      zIndex: 9999
    }),
    control: (_, { selectProps: { width } }) => ({
      width: width
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#c3c3c3',
      marginTop: '0.2em'
    }),
    input: (provided) => ({
      ...provided,
      marginTop: '0.5em'
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  };

  const handleChangeClient = (x) => {
    let newUrl = x.clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');
    history.push(`/calendar/${newUrl}`);
  };

  return (
    <>
      <div className="content ht-100v pd-0">
        <div className="content-header">
          <div className="content-search">
            <SearchIcon />
            <Select
              styles={customStyles}
              className="form-control"
              type="search"
              placeholder="Search clients"
              onChange={(x) => handleChangeClient(x)}
              options={customerList.map((customer) => ({
                ...customer,
                label: customer.clientName,
                value: customer.clientName
              }))}
              hideSelectedOptions={true}
              controlShouldRenderValue={false}
            />
          </div>
        </div>
        <div className="d-flex p-2 align-items-center justify-content-between">
          <div className="ps-2">
            {/* Breadcrumbs */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                <li class="breadcrumb-item">
                  <a href="/clients">Clients</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Onboarding
                </li>
              </ol>
            </nav>
            <h4 className="mg-b-0 tx-spacing--1">New Client Onboarding</h4>
          </div>
        </div>
        <NewClientOnboarding callbackFromParent={() => {}} />
      </div>
    </>
  );
};

export default NewClient;
